import { CustomSyllabusPage } from '@/components/CustomSyllabusPage'
import { Loading } from '@/components/Loading'
import { SyllabusTabsToElement } from '@/constants'
import { fetchItems } from '@/fetchers'
import { Syllabus } from '@/kontent/content-types'
import { WpStage as WpStageModel } from '@/kontent/content-types/wp_stage'
import { contentTypes } from '@/kontent/project/contentTypes'
import { fetchAllGlossaries } from '@/pages/page-api/all-glossaries'
import {
	CommonPageProps,
	CustomSyllabusTab,
	KontentCurriculumCommonResultData,
	TaxoStageWithLifeSkill,
} from '@/types'
import {
	filterPreviewableSyllabusesOnly,
	getCommonFilteredGlossariesWithFilteredTags,
	getTaxoCodenames,
	isAllowPreviewExternalSyllabus,
} from '@/utils'
import { fetchPageApiAllAssets } from '@/utils/assets/fetchPageApiAllAssets'
import { fetchPageApiAllPublishedAssets } from '@/utils/assets/fetchPageApiAllPublishedAssets'
import { useQuery } from '@tanstack/react-query'

export default function WpStage(
	props: CommonPageProps<
		WpStageModel,
		KontentCurriculumCommonResultData<WpStageModel>
	>,
) {
	const { preview } = props

	const { data: glossariesAssetsResponse, isFetched: isFetchedGlossaries } =
		useQuery(
			[
				'WpStage_glossary',
				props.data.pageResponse.item.system.codename,
				preview,
			],
			async () => {
				const responseGlossaries = await fetchAllGlossaries()
				const syllabuses = await fetchItems<Syllabus>(
					'syllabus',
					[
						contentTypes.syllabus.elements.syllabus.codename,
						contentTypes.syllabus.elements.doredirect.codename,
						contentTypes.syllabus.elements.allowpreview.codename,
					].join(','),
				)

				// published syllabus
				const syllabusesItems = isAllowPreviewExternalSyllabus()
					? syllabuses.items.filter(filterPreviewableSyllabusesOnly)
					: syllabuses.items

				const filteredGlossaries =
					getCommonFilteredGlossariesWithFilteredTags(
						responseGlossaries.pageProps.glossaries,

						syllabusesItems,
					)
				const fetcher = preview
					? fetchPageApiAllAssets
					: fetchPageApiAllPublishedAssets
				const responseAssets = await fetcher()
				return {
					glossaries: filteredGlossaries,
					assets: responseAssets.pageProps.assets.filter((asset) => {
						return (
							asset.resource_type.length &&
							asset.resource_type.every(
								(tag) => !tag.codename.includes('ace_'),
							)
						)
					}),
				}
			},
		)

	let initialSelectedStages: TaxoStageWithLifeSkill[] = getTaxoCodenames(
		props.data.pageResponse.item.elements.stages__stages,
	)
	if (initialSelectedStages.includes('stage_6')) {
		initialSelectedStages = [...initialSelectedStages, 'life_skills']
	}

	if (!isFetchedGlossaries) {
		return (
			<div className="nsw-container pt-8">
				<Loading />
			</div>
		)
	}

	return (
		<>
			<CustomSyllabusPage
				{...props}
				data={{
					...props.data,
					glossaries: glossariesAssetsResponse.glossaries,
					assets: glossariesAssetsResponse.assets,
				}}
				initialSelectedStages={initialSelectedStages}
				initialSelectedTabs={
					Object.keys(SyllabusTabsToElement) as CustomSyllabusTab[]
				}
			/>
		</>
	)
}
